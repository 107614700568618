@import 'styles/variables';
@import 'styles/shadows';
@import 'styles/typography';
@import 'styles/shared/Input';

.form-datepicker {
  width: 100%;

  &__icon {
    position: absolute;
    top: 50%;
    right: 18px;
    z-index: 10;
    transform: translateY(-50%);
    color: $color-dark-grey;
    cursor: pointer;
  }

  &__input {
    position: relative;
  }

  &__header {
    @include body-large;
    font-weight: 600;
    padding: 0;

    @media #{$breakpoint-md-up} {
      padding: 10px 10px;
    }
  }

  &__navigation {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    transition: 0.2s linear all;
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 5px 2px 2px;
    border-radius: 24px;

    svg {
      width: 18px !important;
      height: 18px !important;
    }

    &:hover {
      background: #f4f2ff;
    }

    &:focus {
      outline: none;
      opacity: 0.8;
    }
  }

  .react-datepicker {
    @include shadow-cards;

    border-radius: 12px;
    overflow: hidden;
    border-color: $color-gray-4;

    &__input-container {
      display: block;
    }

    &-wrapper {
      display: block;
    }

    &-popper {
      z-index: 20;

      &[data-placement^='bottom'] {
        margin-top: -6px;
      }

      &[data-placement^='top'] {
        margin-bottom: 0;
      }
    }

    &__header {
      background: transparent;
      border-color: $color-light-purple;
    }

    &__current-month {
      @include heading-5;

      color: $color-black;
      font-family: $font-geomanist;
      margin-bottom: 10px;
    }

    &__month {
      margin-top: 12px;
      padding: 0 10px 18px;
    }

    &__day {
      color: $color-gray-8;
      font-family: $font-geomanist;
      width: 36px;
      line-height: 36px;
      font-size: 16px;
      font-weight: 500;

      @media #{$breakpoint-md-up} {
        width: 42px;
        line-height: 42px;
      }

      &--selected {
        background: $gradient-buttons-linear;
        color: #fff;
        border-radius: 50%;
      }

      &--outside-month {
        color: $color-medium-grey;
      }

      &--disabled {
        color: $color-light-craft;
      }

      &--keyboard-selected {
        background-color: transparent;
      }

      &--today {
        font-weight: 500;
        position: relative;

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: $color-primary-purple;
          left: 50%;
          top: 3px;
          transform: translateX(-50%);
        }
      }

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: $color-light-purple;
        border-radius: 50%;
      }
    }

    &__day-name {
      color: $color-dark-grey;
      font-size: 12px;
      font-family: $font-geomanist;
      width: 36px;
      line-height: 2rem;

      @media #{$breakpoint-md-up} {
        width: 42px;
      }
    }
  }
}
