@keyframes rotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes moveSlideshow {
  100% {
    transform: translateX(-50%);
  }
}

@keyframes slideUpFadeIn {
  0% {
    transform: translate3d(0, 24px, 0);
    opacity: 0;
  }
  50% {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0px, 0);
    opacity: 100%;
  }
}

@keyframes tinySlideUpFadeIn {
  0% {
    transform: translate3d(0, 0px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, -5px, 0);
    opacity: 100%;
  }
}

@keyframes slideUpFadeOut {
  0% {
    transform: translate3d(0, 0px, 0);
    opacity: 100%;
  }
  100% {
    transform: translate3d(0, -100px, 0);
    opacity: 0;
  }
}

@keyframes slideInLeft {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes slideOutRight {
  from {
    margin-left: 0%;
    width: 100%;
  }
  to {
    margin-left: 100%;
    width: 300%;
  }
}

@keyframes slideInRight {
  from {
    margin-left: -100%;
    width: 100%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes slideOutLeft {
  from {
    margin-left: 0%;
    width: 100%;
  }
  to {
    margin-left: -100%;
    width: 100%;
  }
}

@keyframes bounceUp {
  from {
    bottom: 0px;
  }
  to {
    bottom: 12px;
  }
}

@keyframes fadeOut {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@keyframes dropIn {
  from {
    height: 0;
  }
  to {
    height: 220px;
  }
}

@keyframes partial-scroll-0 {
  0%,
  15% {
    transform: translateY(0);
  }
  40%,
  50% {
    transform: translateY(-23.8%);
  }
  80%,
  90% {
    transform: translateY(-47.3%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes partial-scroll-1 {
  0%,
  15% {
    transform: translateY(0);
  }
  40%,
  50% {
    transform: translateY(-30%);
  }
  80%,
  90% {
    transform: translateY(-50.5%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes partial-scroll-2 {
  0%,
  15% {
    transform: translateY(0);
  }
  40%,
  50% {
    transform: translateY(-27.5%);
  }
  80%,
  90% {
    transform: translateY(-50.5%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes partial-scroll-3 {
  0%,
  15% {
    transform: translateY(0);
  }
  40%,
  50% {
    transform: translateY(-25.5%);
  }
  80%,
  90% {
    transform: translateY(-47.5%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes partial-scroll-4 {
  0%,
  15% {
    transform: translateY(0);
  }
  40%,
  50% {
    transform: translateY(-28.3%);
  }
  80%,
  90% {
    transform: translateY(-51.6%);
  }
  100% {
    transform: translateY(0);
  }
}
